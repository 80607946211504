<template>
  <section
    class="filter tw-relative tw-flex tw-items-center"
    :class="[showFilterOptions ? 'tw-z-20' : null]"
  >
    <!-- <transition name="fade-transform" mode="out-in">
      <FilterModal
        title="Filter Deals"
        v-if="showFilterOptions"
        @close="showFilterOptions = false"
        :show="showFilterOptions"
        class="acknowledge__modal"
        :showBackButton="false"
      >
        <template v-slot:content>
          <div
            class="tw-max-w-[378px] tw-w-full tw-mx-auto tw-mt-6 tw-border-b tw-mb-5 tw-border-[#F0F4FD] tw-pr-[25px]"
          >
            <h2 class="tw-text-secondary tw-font-bold tw-text-lg tw-mb-3">
              By Status
            </h2>
            <label
              :for="option"
              class="tw-flex tw-items-center tw-mb-4 tw-cursor-pointer"
              v-for="(option, index) in filterOptions"
              :key="option + index"
            >
              <span
                class="tw-flex tw-items-center tw-rounded tw-h-8 tw-w-8 tw-rounded-full tw-px-[10px] tw-mr-4"
                :class="option.toLowerCase()"
              ></span>
              {{ option }}
              <input
                class="tw-h-6 tw-w-6 tw-ml-auto"
                v-model="filterValue"
                type="radio"
                name="status"
                :value="option"
                :id="option"
              />
            </label>
          </div>
          <div class="tw-max-w-[378px] tw-w-full tw-mx-auto tw-mt-5">
            <h2 class="tw-text-secondary tw-font-bold tw-text-lg tw-mb-3">
              By Date
            </h2>
            <InputFieldLabel label="Start Date" :inputActive="!!startDate">
              <Datepicker
                class="tw-mb-6"
                placeholder="Start Date"
                :upperLimit="new Date()"
                :enableTimePicker="false"
                @cleared="startDate = new Date(undefined)"
                v-model="startDate"
              ></Datepicker>
            </InputFieldLabel>
            <InputFieldLabel
              label="End Date"
              :inputActive="!!endDate"
              v-if="startDate"
            >
              <Datepicker
                placeholder="End Date"
                :lowerLimit="startDate"
                :upperLimit="new Date()"
                :enableTimePicker="false"
                @cleared="endDate = new Date(undefined)"
                v-model="endDate"
              ></Datepicker>
            </InputFieldLabel>
          </div>
          <div
            class="tw-flex tw-justify-between tw-items-center tw-mt-[50px] tw-w-full tw-mx-auto tw-max-w-[378px]"
          >
            <button
              @click="clearOptions"
              class="tw-w-full tw-h-[50px] tw-rounded tw-w-10 tw-text-secondary tw-font-CircularStd-medium tw-font-medium"
            >
              Clear
            </button>
            <div class="tw-w-full tw-max-w-[86px]">
              <CustomButton
                title="Filter"
                @click="processFilterSelection"
                :disabled="
                  (!filterValue && !isDateValid) ||
                    (!isDateValid && !isFilteredByStatus)
                "
              />
            </div>
          </div>
        </template>
      </FilterModal>
    </transition> -->
    <div
      class="tw-absolute tw-bg-white filter-options__div tw-p-6"
      v-bind="$attrs"
      v-if="showFilterOptions"
    >
      <h2
        class="tw-text-secondary tw-font-CircularStd-medium tw-font-medium tw-text-normal tw-mb-3"
      >
        Filter {{ title }}
      </h2>
      <button
        class="tw-absolute tw-right-[16px] tw-top-[16px] hover:tw-bg-white"
        @click="$emit('filter-click')"
      >
        <img src="@/assets/img/close-dark.svg" alt="close icon" class="" />
      </button>
      <div
        class="tw-max-w-[378px] tw-w-full tw-mx-auto tw-mt-6 tw-mb-5 tw-border-[#F0F4FD]"
      >
        <label
          :for="option"
          class="tw-flex tw-items-center tw-mb-4 tw-cursor-pointer tw-text-secondary tw-text-sm"
          v-for="(option, index) in filterOptions"
          :key="option + index"
        >
          <span
            class="tw-flex tw-items-center tw-rounded tw-h-8 tw-w-8 tw-rounded-full tw-px-[10px] tw-mr-4"
            :class="option.toLowerCase()"
          ></span>
          {{ option }}
          <input
            class="tw-h-5 tw-w-5 tw-ml-auto"
            v-model="filterValue"
            type="radio"
            name="status"
            :value="option"
            :id="option"
          />
        </label>
      </div>
      <!-- <div class="" v-if="title === 'Deals'">
        <h2
          class="tw-text-secondary tw-font-CircularStd-medium tw-font-medium tw-text-normal tw-mb-3"
        >
          By Sector
        </h2>
        <Dropdown :options="industries" name="sector" :validate="false" />
      </div> -->
      <div
        class="tw-flex tw-justify-between tw-items-center tw-mt-[50px] tw-w-full tw-mx-auto tw-max-w-[378px]"
      >
        <button
          @click="clearOptions"
          class="tw-w-full tw-h-[50px] tw-rounded tw-w-[80px] tw-text-secondary tw-font-CircularStd-medium tw-font-medium"
        >
          Clear
        </button>
        <div class="tw-w-full tw-max-w-[86px]">
          <CustomButton
            title="Filter"
            @click="processFilterSelection"
            :disabled="
              (!filterValue && !isDateValid) ||
                (!isDateValid && !isFilteredByStatus)
            "
          />
        </div>
      </div>
    </div>
    <button
      class="tw-w-5 tw-h-5 filter__button filter__button--default tw-flex tw-gap-x-2 tw-items-center tw-text-[#949DBC] tw-tracking-[-0.12px] tw-text-sm hover:tw-text-gray-dark3 tw-justify-center"
      @click="$emit('filter-click')"
    >
      <img
        class="tw-h-5 tw-w-5"
        src="@/assets/img/filter.svg"
        alt="filter icon"
      />
    </button>
  </section>
</template>

<script>
  import Checkbox from "./Checkbox";
  import CustomButton from "../general/BtnComponent";
  import Datepicker from "vue3-datepicker";
  import FilterModal from "@/components/general/sidemodal/SideModal";
  import InputFieldLabel from "./InputFieldLabel";
  import Dropdown from "./Dropdown";
  import { industries } from "@/utils/helpers";
  export default {
    name: "Filter",
    emits: ["filter-click", "filter-selected"],
    data() {
      return {
        industries,
        filterValue: "",
        startDate: new Date(undefined),
        endDate: new Date(undefined),
        filterTypes: [
          {
            name: "Status",
            value: "status",
          },
          {
            name: "Date",
            value: "updatedAt",
          },
        ],
        filter: "",
      };
    },
    props: {
      showFilterOptions: {
        type: Boolean,
        required: true,
        default: () => false,
      },
      filterOptions: {
        type: Array,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
    },
    components: {
      Checkbox,
      Datepicker,
      CustomButton,
      FilterModal,
      InputFieldLabel,
      Dropdown,
    },
    watch: {
      filterValue(val) {
        if (val && val.length > 0) {
          this.startDate = new Date(undefined);
          this.endDate = new Date(undefined);
        }
      },
      startDate(val) {
        if (String(val) !== "Invalid Date") {
          this.filterValue = "";
        }
      },
      endDate(val) {
        if (String(val) !== "Invalid Date") {
          this.filterValue = "";
        }
      },
    },
    computed: {
      isFilteredByStatus() {
        return !!this.filterValue;
      },
      isDateValid() {
        return (
          String(this.startDate) !== "Invalid Date" &&
          String(this.endDate) !== "Invalid Date"
        );
      },
    },
    methods: {
      clearOptions() {
        this.filterValue = "All";
        this.$emit("filter-selected", {
          value: "",
        });
      },
      processFilterSelection() {
        if (this.isFilteredByStatus) {
          const status =
            this.filterValue === "Active" ? "Approved" : this.filterValue;
          this.$emit("filter-selected", {
            value: status,
            type: this.getType(this.title),
          });
        } else {
          this.$emit("filter-selected", {
            value: {
              from: new Date(this.startDate).toISOString(),
              to: new Date(this.endDate).toISOString(),
            },
          });
        }
        this.$emit("filter-click");
      },
      getType(title) {
        let type = null;
        switch (title) {
          case "Deals":
            type = "status";
            break;

          default:
            break;
        }
        return type;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .filter {
    z-index: 11;
    &-options__div {
      background: #ffffff;
      box-shadow: 0px 2px 4px rgba(44, 73, 137, 0.3);
      border-radius: 4px;
      width: 290px;
      top: 0;
      right: 0;
    }
    &-close__button {
      right: 16px;
      top: 16px;
    }
    &__button {
      &--default {
        box-sizing: border-box;
        border-radius: 4px;
        width: 20px;
        height: 20px;
      }
      &:hover {
        background-color: #fff;
      }
    }
  }

  .approved,
  .completed {
    background-color: rgba(39, 174, 96, 0.1);
    color: #27ae60;
  }

  .pending {
    background-color: rgba(243, 139, 0, 0.1);
    color: #f38b00;
  }

  .rejected,
  .failed {
    background-color: rgba(235, 87, 87, 0.08);
    color: #db4343;
  }
  .terminated {
    background-color: rgba(219, 67, 67, 0.1);
    color: #db4343;
  }
</style>
