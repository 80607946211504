<template>
  <div
    class="tw-flex tw-items-center tw-gap-2 tw-cursor-pointer tw-h-10"
    :class="{
      'tw-border-primary': selected === true,
    }"
    @click="toggleCheckbox"
  >
    <input
      ref="checkbox"
      class="tw-cursor-pointer tw-w-5 tw-h-5"
      type="checkbox"
      :value="value"
      id="toggle"
      :checked="selected"
      :disabled="disabled"
    />
    <div class="tw-w-10/12 tw-flex tw-items-center tw-justify-start">
      <h3 class="tw-text-sm tw-text-gray-dark3">{{ title }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckboxInput",

  emits: ["checked"],

  props: {
    title: { type: String, default: () => "", required: true },
    label: { type: String, default: () => "", required: true },
    value: { type: String, default: () => "", required: true },
    checked: { type: Boolean, default: () => "", required: true },
    disabled: { type: Boolean, default: () => false },
  },
  data() {
    return {
      selected: false,
    };
  },
  watch: {
    checked(val) {
      this.selected = val;
    },
  },
  mounted() {
    this.selected = this.checked;
  },

  methods: {
    toggleCheckbox() {
      this.selected = !this.selected;
      this.$emit("checked", {
        value: this.selected,
        name: this.value,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.Active {
  background: rgba(39, 174, 96, 0.08);
}

.Pending {
  background: rgba(226, 185, 59, 0.08);
}
.Rejected {
  background: rgba(235, 87, 87, 0.08);
}
</style>
